<template>
  <div class="PageBox">
    <NavBar :title="$t('userInfo.default[0]')" :page-number="-1"/>
    <div class="ScrollBox">
      <van-form style="padding-right: 10px;">
        <div class="infoPanel">

          <div class="border-grey field"  @click="showHeader = true">
            <div style="width: 10%;">
              <img src="~@/assets/img/user/11794.png" />
            </div>
            <div style="width: 90%;">
              <div style="display: flex;align-items: center;justify-content: space-between; ">
                <div style="display:flex; align-items: center;justify-content: space-between; ">
                  <div class="userFace">
                    <img v-if="UserInfo.header.includes('head_')" :src="`./static/head/${UserInfo.header}`" />
                    <img v-else :src="UserInfo.header" />
                  </div>
                  <div style="margin-left: 20px;">
                    {{$t('userInfo.default[1]')}}
                  </div>
                </div>
                <div>
                  <img src="~@/assets/img/user/4.png" />
                </div>
              </div>
            </div>
          </div>

          <div class="border-grey field">
            <div style="width: 10%;">
              <img src="~@/assets/img/user/face.png" />
            </div>
            <div  style="width: 40%;">
              {{$t('userInfo.default[15]')}}
            </div>
            <div>
              <van-field input-align="right" style="width:205px;
               background-color: rgba(255,255,255,0.13);"
                         v-model="UserInfo.nickname"/>
            </div>
          </div>

          <div class="border-grey field">
            <div style="width: 10%;">
              <img src="~@/assets/img/user/accout.png" />
            </div>
            <div  style="width: 40%;">
              {{$t('userInfo.default[2]')}}
            </div>
            <div>
              {{UserInfo.username ? UserInfo.username : $t('userInfo.default[8]')}}
            </div>
          </div>

          <div class="border-grey field">
            <div style="width: 10%;">
              <img src="~@/assets/img/user/19459.png" />
            </div>
            <div style="display: flex; justify-content: space-between; width: 90%;">
              <div style="display: flex; align-items: center; width: 80%;">
                <div style="margin-right: 20px;">
                  {{$t('userInfo.default[16]')}}
                </div>
                <div>
                  {{UserInfo.idcode}}
                </div>
              </div>
              <div>
                <img src="~@/assets/img/user/19452.png" @click="copyCode()" />
              </div>
            </div>
          </div>


          <div class="border-grey field" @click="showPassWord = true">
            <div style="width: 10%;">
              <img src="~@/assets/img/user/11795.png" />
            </div>
            <div  style="width: 40%;">
              {{$t('userInfo.default[6]')}}
            </div>
          </div>

          <div class="border-grey field">
            <div style="width: 10%;">
              <img src="~@/assets/img/user/19462.png" />
            </div>
            <div  style="display:flex;">
              <div>{{$t('userInfo.default[4]')}}</div>
              <div style="margin-left:10px; color: #f0a903">
                {{UserInfo.credit}}
              </div>
            </div>

          </div>
        </div>
      </van-form>
      <div class="centerPanel" style="margin-top: 20px;padding: 10px 0px">
        <van-button class="gradient-btn" @click="setUserInfo">{{ $t('userInfo.label[6]') }}</van-button>
      </div>
    </div>

    <van-action-sheet
      v-model="showHeader"
      :title="$t('userInfo.default[9]')"
      close-on-popstate
      style="background-color: #20242e;"
    >

      <van-radio-group v-model="radioHeader" >
        <van-grid clickable icon-size="45" column-num="5">
          <!--<van-grid-item
            :icon="`./static/head/head_${item}.png`"
            v-for="item in 40"
            :key="item"
            @click="selectHeader(item)"
          />-->
          <van-grid-item
              :icon="`./static/head/head_2.png`"
              @click="selectHeader(2)"
          />
        </van-grid>
      </van-radio-group>
      <div style="display: flex;justify-content: center;">
        <van-uploader max-count="1"
                      :after-read="afterRead">
          <div style="text-align: center;margin: 40px 0; ">
            <div><img src="~@/assets/img/skinbitcoin/caxj.png"></div>
            <div style="color: var(--bc-text-color);">{{$t('userInfo.default[17]')}}<!--请上传图片修改头像--></div>
          </div>
        </van-uploader>
        <div></div>
      </div>
    </van-action-sheet>
    <van-action-sheet
      v-model="showPassWord"
      close-on-popstate
      style="background-color: #20242e;"
    >
      <div style="display: flex; align-items:center; padding: 0 16px; margin-top: 20px;">
        <div style="width: 106px; font-size: 12px; margin-right: 10px; color: var(--bc-text-color-1);">{{$t('userInfo.label[0]')}}</div>
        <div class="border-grey" style="background-color: #212427; width: 100%;">
          <van-field
              v-model.trim="postData.o_password"
              type="password"
              :placeholder="$t('userInfo.placeholder[0]')"
              clearable
              size="large"
              style="font-size: 10px;"
          />
        </div>
      </div>

      <div style="display: flex; align-items:center; padding: 0 16px; margin-top: 20px;">
        <div style="width: 106px; font-size: 12px; margin-right: 10px; color: var(--bc-text-color-1);">{{$t('userInfo.label[1]')}}</div>
        <div class="border-grey" style="background-color: #212427; width: 100%;">
          <van-field
              v-model.trim="postData.n_password"
              type="password"
              :placeholder="$t('userInfo.placeholder[1]')"
              clearable
              size="large"
              style="font-size: 10px;"
          />
        </div>
      </div>

      <div style="display: flex; align-items:center; padding: 0 16px; margin-top: 20px;">
        <div style="width: 106px; font-size: 12px; margin-right: 10px; color: var(--bc-text-color-1);">{{$t('userInfo.label[2]')}}</div>
        <div class="border-grey" style="background-color: #212427; width: 100%;">
          <van-field
              v-model.trim="postData.r_password"
              type="password"
              :placeholder="$t('userInfo.placeholder[2]')"
              clearable
              size="large"
              style="font-size: 10px;"
          />
        </div>
      </div>

      <div style="text-align: left;padding: 18px">
        <div style="margin-left: 20px;color: var(--bc-text-color);font-size: 14px">{{$t('resetpwd2[2]')}}</div>
      </div>
      <textarea id="inputBox" style="position: absolute;top: 0;left: 0;opacity: 0;z-index: -10;"></textarea>
      <div style="padding: 6px;padding-bottom: 5px; text-align: center;">
        <van-button class="gradient-btn" @click="setpwd">{{ $t("userInfo.default[12]") }}</van-button>
      </div>
    </van-action-sheet>
  </div>
</template>

<script>

import cofing from "../../config";

export default {
  name: "Info",
  components: {},
  props: [],
  data() {
    return {
      showHeader: false,
      showPassWord: false,
      showPayWord: false,
      radioHeader: "",
      postData: {},
    };
  },
  computed: {},
  watch: {},
  created() {
    this.postData.header = this.UserInfo.header;
    this.$Model.GetUserInfo();
    console.log(this.UserInfo);
  },
  mounted() {},
  activated() {},
  destroyed() {},
  methods: {
    copyCode() {
      var that = this;
      var copyBox = document.getElementById("inputBox");
      copyBox.value = this.bankInfo.idcode;
      copyBox.select();
      document.execCommand("Copy"); // 执行浏览器复制命令
      this.$Dialog.Toast(this.$t('recharge.label[5]'));
    },
    selectHeader(index) {
      this.postData.header = `head_${index}.png`;
      this.UserInfo.header  = `head_${index}.png`;
      if (this.showHeader) {
        this.showHeader = false;
      }
    },
    setpwd(){
      if (this.showPassWord) {
        this.showPassWord = false;
      }
    },
    setUserInfo() {
      let that = this;
      this.postData['nickname'] = this.UserInfo.nickname
      this.$Model.SetUserInfo(this.postData, (data) => {
        if (data.code == 1) {
          if (that.showHeader) {
            that.showHeader = false;
          }
          if (that.showPassWord) {
            that.showPassWord = false;
          }
          if (that.showPayWord) {
            that.showPayWord = false;
          }
          that.postData = {};
          that.$Dialog.Close();
        }
      });
    },
    clearCache() {
      // localStorage.clear();
      // this.$Model.GetBackData();
      // this.$router.push("/login");
      this.$Model.Logout()
    },
    afterRead(file) {
      file.status = 'uploading'
      file.message = this.$t('upload[0]')
      this.uploadImgs(file)
    },
    uploadImgs(file) {
      if (!file.file.type.match(/image/)) {
        file.status = "failed";
        file.message = this.$t("upload[1]");
        return;
      }
      console.log(2);
      this.compressImg(file);
    },
    compressImg(file) {
      let that = this;
      this.$Util.CompressImg(file.file.type, file.content, 750, (image) => {
        let param = new FormData();
        param.append("token", localStorage["Token"]);
        param.append("type", 3);
        param.append("image", image, file.file.name);
        this.$Model.UploadImg(param, (data) => {
          if (data.code == 1) {
            file.message = this.$t("upload[2]");
            file.status = "success";
            file.url = data.url;
            that.UserInfo.header = cofing.ApiUrl + data.url;
            that.postData.header = that.UserInfo.header;
            that.showHeader = false;
            // this.submitTask();
            //显示提现弹窗
            console.log("upload success");
          } else {
            file.status = "failed";
            console.log("upload false");
            // file.message = this.$t("upload[3]");
          }
        });
      });
    },
  },
};
</script>
<style scoped>
  .PageBox {

  }

.van-cell__left-icon {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}
.van-icon__image {
  width: 100%;
  height: 100%;
}
.van-cell__title {
  font-size: 14px;
}

.infoPanel {
  margin-top: 20px;
  border-radius: 10px;
  background-color: var(--panel_color);
  padding-left: 10px;
  display: grid;
  grid-row-gap: 20px;

  .field {
    display: flex;
    align-items: center;
    padding: 0 12px 0 12px;
    height: 42px;
    font-size: 12px;
  }

}

.van-cell {
  background-color: transparent;
}
.ScrollBox {
  padding: 10px;
}

  .van-cell>>>.van-icon{
    font-size: 19px;
  }

.ScrollBox >>> .van-cell {
  order-radius: 10px;
  border-radius: 5px;

}
.ScrollBox >>> .van-cell::after {
  display: none;
}
.ScrollBox >>> .van-cell__title {
  font-size: 14px;

  font-weight: normal;
}

.ScrollBox >>> .van-cell__value {
  display: flex;
  color:var(--cell_placeholder);
  justify-content: flex-end;
  padding-right: 8px;
}
.ScrollBox >>> .userFace {
  display: flex;
  width: 30px;
  height: 30px;
  background: #eff0f2;
  border-radius: 50%;
  padding: 0px;
  overflow: hidden;
}

  .van-cell >>> .van-field__control {
    color: var(--cell_title) !important;
    padding-left: 7px;
    height: 100%;
  }

  .van-cell >>> .van-field__control::-webkit-input-placeholder {
    color: var(--cell_placeholder) !important;

  }

  .van-cell >>> .van-field__control::-moz-placeholder {
    color: var(--cell_placeholder) !important;

  }



.ScrollBox >>> .userFace img {
  width: 100%;
}
.ScrollBox >>> .isTrue {
  color: #333;
  padding-right: 20px;
}
.van-cell:nth-child(4) >>> .van-cell__left-icon img {
  transform: scale(1.1);
}
.ScrollBox >>> .van-cell__value {
  font-size: 15px;
}

.btn {
  padding: 10px 10px;
  border-radius: 5px;
  background-color: var(--button_bg);
  color: #FFFEFF;
  font-size: 18px;
  text-align: center;
  margin: 15px 10px 10px;
}

  .van-cell--large {
    overflow: hidden;
    padding: 0 18px 0 10px;
    height:42px;

  }
  .van-action-sheet__header {
    text-align: start;
    padding-left: 16px;
    color: #ffffff;
  }



</style>
